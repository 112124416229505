* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wwdFeatures {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 750px;
    padding-bottom: 120px;
    padding-left: 15px;
}



.wwdFeatures h3 {
    line-height: 2;
    font-size: 25px;
    margin-top: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.wwd-card-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 1200px;
    margin: 0 auto; 
}

.wwd-card {
    perspective: 1000px;
    margin: 20px; 
    width: 280px; 
    height: 250px;
    border-radius: 15px; 
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    transition: transform 0.3s, box-shadow 0.3s;
}

.wwd-card:hover {
    transform: translateY(-5px); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 8px 24px rgba(0, 0, 0, 0.25);
}



.wwd-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.wwd-card:hover .wwd-card-inner {
    transform: rotateY(180deg);
}

.wwd-card-front,
.wwd-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.wwd-card-back {
    background-color: #a6c2e1; 
    color: black; 
}

.wwd-card-front {
    background-color: #7e92c2; 
    color: white; 
}

.wwd-card-back {
    transform: rotateY(180deg);
}

.wwd-card-front h4 {
    font-weight: bold;
    font-size: 18px; 
    text-align: center;
}

.wwd-card-back p {
    font-size: 16px; 
    color: black; 
    text-align: center;
    line-height: 1.4;
    margin-bottom: 2px;
}

@media screen and (max-width: 640px) {
    .wwdFeatures{
        padding-top: 750px;
    }
}