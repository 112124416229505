.card-wrapper {
  max-width: 1200px; 
  margin: 0 auto;
  padding: 20px; 
  text-align: center; 
}


.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  gap: 50px; 
  justify-content: center; 
  margin: 0;
  padding-left: 180px;
  padding-top: 100px; 
  padding-bottom: 200px;
  max-width: 1000px; 
  width: 100%; 
  box-sizing: border-box; 
}

.card-wrapper h3 {
  margin-bottom: 2px; 
  font-size: 2em; 
  color: #333; 
  margin-top: 800px;
}

.card {
  width: 400px; 
  height: 300px; 
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 20px;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
}

.card-front {
  font-size: 20px; 
}

.card-back {
  transform: rotateY(180deg); 
}

@media screen and (max-width: 640px){
  .card-container{
    padding-left: 24px;
  }
}
