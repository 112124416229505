.contact-wrapper {
    text-align: center;
    padding: 60px 20px; /* Reduced padding for outer wrapper */
  }
  
  .contact-heading {
    font-size: 2.2em; /* Reduced font size */
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 30px; /* Reduced space between heading and content */
    color: #333;
    margin-top: 20px; /* Reduced top margin */
  }
  
  .contact-box {
    display: flex;
    justify-content: center;
    padding: 30px; /* Reduced padding inside the box */
    border: 2px solid #ddd;
    border-radius: 12px;
    background-color:#a6c2e1;
    /* background-color:  #d3dae8; */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Reduced max-width for the contact box */
    margin: 0 auto;
    margin-bottom: 80px; /* Reduced bottom margin */
    margin-top: 30px; /* Reduced top margin */
  }
  
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .contactleft {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    padding: 12px; /* Reduced padding inside each item */
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 15px; /* Reduced space between items */
    width: 100%;
    max-width: 450px; /* Reduced max-width to keep items more compact */
  }
  
  .contact-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .contact-icon {
    font-size: 20px;  /* Slightly reduced icon size */
    color: #007bff;
    margin-right: 12px;  /* Reduced margin */
  }
  
  .contact-info {
    display: flex;
    flex-direction: row; /* Aligns label and value horizontally */
    justify-content: flex-start; /* Aligns items to the left */
    align-items: center; /* Vertically centers the text */
  }
  
  .contact-info h2 {
    margin-right: 8px;  /* Reduced margin after the heading */
    margin-bottom: 0;
    margin-top: 0;
    font-size: bold;
    padding-right: 3px;  /* Reduced padding after the colon */
  }
  
  .contact-info p,
  .contact-item a {
    font-size: 14px; /* Reduced font size of text */
    color: #333;
    margin: 0;
  }
  
  .contact-item a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-item a:hover {
    text-decoration: underline;
  }
  
  .map-container {
    margin-top: 10px;
    margin-bottom: 15px; /* Reduced margin below the map */
  }
  
  .location-item {
    margin-top: 20px; /* Reduced margin above the location section */
  }
  
  @media (max-width: 768px) {
    .contact-box {
      flex-direction: column;
      padding: 10px;
    }
  
    .contactleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 20px 0;
    }
  
    .contact-item {
      width: 100%;
      max-width: 400px;
      margin: 10px 0;
    }
  
    .contact-item a {
      font-size: 9px; /* Adjusted font size for smaller screens */
    }
  }
  
  @media (max-width: 375px) {
    .contact-box {
      flex-direction: column;
      padding: 10px;
    }
  
    .contactleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 20px 0;
    }
  
    .contact-item {
      width: 100%;
      max-width: 400px;
      margin: 10px 0;
    }
  
    .contact-item a {
      font-size: 6px; /* Adjusted font size for smaller screens */
    }
  }