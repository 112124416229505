* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
}

.aptitudestyles {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 15px;
}

.aptitudestyles p {
    max-width: 1200px;
    text-align: justify;
    line-height: 1.6;
    font-size: 20px;
    padding: 0 15px;
}

.aptitudestyles h3 {
    line-height: 2;
    font-size: 25px;
    margin-top: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.aptitudestyles h2 {
    line-height: 2;
    font-size: 25px;
    margin-top: 80px;
    margin-bottom: 30px;
    font-weight: bold;
}

.card-container1 {
    display: flex;
    justify-content: space-around; 
    /* flex-wrap: nowrap;  */
    /* margin-rigth: 10px; */
    max-width: 1200px; 
    /* margin: 0 auto;  */
    padding-left: 0;
    margin-bottom: 80px;
}

.card1 {
    background: #a6c2e1;
    border-radius: 10px;
    padding: 20px;
    margin: 5px; 
    width: 550px; 
    height: 310px;
    text-align: left;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.card1:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card1 h4 {
    margin-bottom: 10px; 
    font-weight: bold;
}

.card1 p {
    font-size: 18px; 
    color: #555; 
    text-align: left;
    line-height: 1.4;
}

.image-container1 {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 1200px; 
}

/* .image-container1 img {
    width: 500px; 
    margin: 30px; 
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container1 img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
} */

.image-container1 img {
    width: 500px;
    margin: 30px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    border: 7px solid  #4f6cb0; 
    border-radius: 10px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.image-container1 img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
    border-color: #a6c2e1; 
}


@media screen and (max-width: 640px){
    .card-container1{
        display: block;
    }

    .card1{
        width: 250px;
        height: 300px;
    }

    .image-container1{
        max-width: 500px;
        /* padding-right: 29px; */
        margin-right: 20px;
    }
}

@media screen and (max-width: 768px){
    .card-container1{
        display: block;
    }

    .card1{
        width: 250px;
        height: 300px;
    }

    .image-container1{
        max-width: 500px;
    }
}

@media screen and (max-width: 1024px){
    .card-container1{
        display: block;
    }

    .card1{
        width: 250px;
        height: 300px;
    }

    .image-container1{
        max-width: 500px;
    }
}