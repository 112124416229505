.creative-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90.21deg, #c1cfe3 -5.91%, #4f6cb0 111.58%);
  overflow: hidden; /* Hide anything overflowing */
  position: relative;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Added padding to give enough space for the logo expansion */
  border-radius: 12px; /* Optional: adds a border radius around the container */
  background-color: #fff; /* Optional: adds a white background to the container */
  animation: pulseBackground 3s infinite;
  overflow: hidden; /* Ensures the logo stays within the bounds of the container */
}

.logo {
  width: 100px;
  height: 100px;
  animation: pulseLogo 5s infinite;
  transition: transform 0.5s ease;
}

/* Background pulse effect */
@keyframes pulseBackground {
  0% {
    background-color: #bec9e3;
  }
  50% {
    background-color: #7e92c2;
  }
  100% {
    background-color: #bec9e3;
  }
}

/* Pulsating logo effect */
@keyframes pulseLogo {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1); /* Reduced scale to avoid overflow */
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
