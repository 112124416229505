* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
}

.seminarstyles {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 15px;
}

.seminarstyles p {
    max-width: 1200px;
    text-align: justify;
    line-height: 1.6;
    font-size: 20px;
    padding: 0 15px;
}

.seminarstyles h3 {
    line-height: 2;
    font-size: 25px;
    margin-top: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.seminarstyles h5{
    font-weight: bold;
}


.image-container-seminar {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 1200px; 
}

/* .image-container-seminar img {
    width: 500px; 
    margin: 30px; 
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container-seminar img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
} */

.image-container-seminar img {
    width: 500px;
    margin: 30px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    border: 7px solid  #4f6cb0; 
    border-radius: 10px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.image-container-seminar img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
    border-color: #a6c2e1; 
}
