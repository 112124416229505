.mask {
    width: 100%;
    height: 100vh;
    position: relative;
}

.intro-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.3;
}

.front {
    width: 100%;
    height: 100%;
}

.front .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    padding-top: 7.5rem; 
}


.front .content h1 {
    font-size: 4rem; 
    color: rgb(217, 200, 200); 
    margin: 0; 
    white-space: nowrap; 
    overflow: hidden; 
    /* border-right: 2px solid white;  */
}

.typing-effect-wrapper {
    display: inline-block;
    animation: typing 4s steps(30, end), blink .75s step-end infinite;
}

.typing-effect-line {
    display: block; 
}

@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}

@keyframes blink {
    from { border-right: 2px solid transparent; }
    to { border-right: 2px solid white; }
}

/* @media screen and (max-width: 640px) {
    .front .content h1 {
        font-size: 4rem;
        padding: 3rem 0 1.5rem;
        color: white;
    }
    .intro-img {
        height: 5000vh; 
    }
} */



@media screen and (max-width: 640px) {
    .intro-img {
        height: 100vh; 
    }

    .front .content {
        padding-top: 0; 
        transform: translate(-50%, -50%); 
    }

    .front .content h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .intro-img {
        height: 100vh; 
    }

    .front .content {
        padding-top: 0; 
        transform: translate(-50%, -50%); 
    }

    .front .content h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .intro-img {
        height: 100vh; 
    }

    .front .content {
        padding-top: 0; 
        transform: translate(-50%, -50%); 
    }

    .front .content h1 {
        font-size: 2rem;
    }
}