* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.gdstyles {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 15px;
}

.gdstyles p {
    max-width: 1200px;
    text-align: justify;
    line-height: 1.6;
    font-size: 20px;
    padding: 0 15px;
    margin-bottom: 50px;
}

.gdstyles h3 {
    line-height: 2;
    font-size: 25px;
    margin-top: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.card-container2 {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 1200px;
    margin: 0 auto; 
}

.card2 {
    perspective: 1000px;
    margin: 10px; 
    /* width: 250px; 
    height: 300px;  */
    width: 250px; 
    height: 420px; 
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.card2:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.card-back{
    background-color: #a6c2e1; 
    color: black; 
}

.card-front{
    background-color:#7e92c2; 
    color:white; 
}

.card-back {
    transform: rotateY(180deg);
}

.card-front h4 {
    font-weight: bold;
    font-size: 18px; 
    text-align: center;
}

.card-back p {
    font-size: 16px; 
    color:black;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 2px;
}

.image-container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
}

/* .image-container2 img {
    width: 500px; 
    margin: 30px; 
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container2 img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
} */

.image-container2 img {
    width: 500px;
    margin: 30px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    border: 7px solid  #4f6cb0; 
    border-radius: 10px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.image-container2 img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
    border-color: #a6c2e1; 
}

@media screen and (max-width: 640px){
    .image-container2{
        /* padding-right: 23px; */
        margin-right: 20px;
    }
}
