/* Center the heading above the content box */
.fored-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2px; /* Add some space between the heading and the content */
  padding-top: 100px;
}

.fored-heading {
  font-size: 36px; /* Adjust size as needed */
  font-weight: bold;
  color: #333; /* You can change the color to match your design */
  text-align: center;
}

.fored-creative-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135vh;
  padding-top: 2px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  /* margin-top: 200px; */
  width: 100%;
  
  /* overflow-x: auto;  */
}

.fored-content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #4f6cb0; 
  text-align: center;
  width: 100%; 
  max-width: 1000px; 
}

.fored-creative-image {
  /* width: 100%; */
  width: 1700px;
  height: 500px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.fored-text-content h1 {
  color: #fff; 
  margin-bottom: 10px;
}

.fored-text-content p {
  color: #fff; 
  font-size: 16px;
  line-height: 1.5;
}


@media (max-width: 768px) {
  .fored-content-box {
    max-width: 90%; 
  }
}

@media (max-width: 480px) {
  .fored-content-box {
    max-width: 100%; 
  }
}

 
 /* .fored {
    max-width: 800px;
    max-height: 800px;
    margin:auto;
    padding-top: 100px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #a6c2e1;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .content {
    padding: 20px;
  }
   */


/* .fored {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 62px;
}
  
.fored img {
    width: 60%;
    max-width: 600px;
    height: auto;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fored img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.fored p {
    max-width: 800px;
    text-align: center;
    line-height: 1.6;
    font-size: 16px;
}
   */