.card-container {
  perspective: 1000px;
}

.card {
  width: 200px;
  height: 300px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  /* border-radius: 15px; 
  overflow: hidden;  */
}

.card.flipped {
  transform: rotateY(180deg);
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 15px;  */
}

.front {
  background: #baa1ba;
} 

.back {
  background: rgba(102, 29, 102, 0.7);
  transform: rotateY(180deg);
}

.card:hover {
  transform: rotateY(180deg);

}



/* .card-container {
    perspective: 1000px;
  }
   
  .card {
    width: 200px;
    height: 300px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .front {
    background: #baa1ba;
  }
  
  .back {
    background:  rgba(102, 29, 102, 0.7);
    transform: rotateY(180deg);
  }
  
  .card:hover {
    transform: rotateY(180deg);
  }
   
 */
