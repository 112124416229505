.cf {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 120px;
}
  
.cf img {
    width: 60%;
    max-width: 150px;
    height: auto;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cf img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cf p {
    max-width: 800px;
    text-align: justify;
    line-height: 1.6;
    font-size: 20px;
    padding: 0 5px;
}

.cf h3{
    line-height: 2;
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: bold;
}

.cf button{
    margin-top: 50px;
}

.submitresumeclass {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 120px;
}

.team-section {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 50px;
    width: 100%;
    max-width: 1000px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px; 
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.team-member:hover {
    transform: translateY(-5px);
}

.team-member img {
    width: 200px; 
    height: 200px; 
    object-fit: cover; 
    margin-bottom: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-member h4 {
    font-size: 20px;
    margin: 10px 0 5px;
    color: #333;
    font-weight: bold;
}

.team-member p {
    font-size: 16px;
    color: #666;
    margin: 0;
    line-height: 1.4;
}
