.team {
  padding: 80px 0;
  position: relative;
  /* background-color: black;  */
}

.team h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.team p {
  color: black;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.team .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.team .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.team .nav.nav-pills .nav-link {
  /* background-color: transparent; */
  /* background: linear-gradient(135deg, #e0eafc 0%, #cfdef3 100%); */
  /* background: linear-gradient(135deg, #b3c7f4 0%, #a2b9d0 100%); */
  /* background: linear-gradient(135deg, #9aaee8 0%, #7e9fb0 100%); */
  /* background: linear-gradient(135deg, #d0e1fc 0%, #b8cde5 100%); */
  background: linear-gradient(135deg, #b5c9f0 0%, #9fb9d7 100%);

  /* background: #3f5b9a; Darker shade of #4f6cb0 */
  /* background:  #8791a9; */
  padding: 17px 0;
  color: black;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.team .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  /* background: linear-gradient(90.21deg, #d88fa5 -5.91%, #9f5bc4 111.58%); */
  background: linear-gradient(90.21deg, #a2b8d9 -5.91%, #4f6cb0 111.58%);
  
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.team .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.team .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#teams.teams-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#teams.teams-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#teams.teams-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.team-imgbx {
  position: relative;
  /* width: 300px; 
  height: 270px;  */
  width: 350px; 
  height: 323px; 
  border-radius: 30px;
  overflow: hidden;
  margin: 0 auto 64px auto; 
}

.team-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #4f6cb0; 
  color: white;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2; 
  transition: opacity 0.3s ease-in-out;
}

.team-info h5{
  font-weight: bold;
}

.team-imgbx::before {
  content: "";
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
  /* background: linear-gradient(90.21deg, #d88fa5 -5.91%, #9f5bc4 111.58%); */
  background: linear-gradient(90.21deg, #c1cfe3 -5.91%, #4f6cb0 111.58%);
  opacity: 0.85;
  /* opacity: 0.5; */
  position: absolute;
  width: 100%;
  height: 0;
  transition: height 0.4s ease-in-out;
  top: 0;
  left: 0;
  z-index: 1; 
}

.team-imgbx:hover::before {
  height: 100%;
}

.team-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
  opacity: 0; 
  width: 100%;
  z-index: 2; 
}

.team-txtx-a {
  margin-top: 1rem;
  margin-left: 8.5rem;
}

.team-imgbx:hover .team-info {
  opacity: 0; 
}

.team-imgbx:hover .team-txtx {
  top: 50%;
  opacity: 1; 
}

.team-txtx h4 {
  font-size: 30px;
  font-weight: 650;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  margin-bottom: 15px;
}

.team-txtx h5 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  margin-bottom: 15px;
  /* color: rgb(52, 30, 173); */
  color: rgb(54, 53, 57);

}

.team-txtx span {
  /* font-style: italic; */
  /* font-weight: bold; */
  font-size: 17px;
  letter-spacing: 0.8px;
}

/* .background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}  */

@media screen and (max-width: 640px) {
  .team-txtx-a { 
    margin-top: 1rem;
    margin-left: 8.3rem;
    
  }
}
